import Plot from 'react-plotly.js';
import I18n from './I18n';

//const loc = I18n.getlocale();

const ChartLayout = {
  legend: {
    traceorder: 'reversed+normal',
    x: 0,
    y: 1,
    xanchor: 'left',
    yanchor: 'top',
  },
  dragmode: 'pan',
  plot_bgcolor: 'rgba(0, 0, 0, 0)', // Set the plot background color to transparent
  paper_bgcolor: 'rgba(0, 0, 0, 0)', // Set the paper background color to transparent

  margin: {
    l: 20,
    r: 0,
    t: 0,
    b: 20
  },
  xaxis: {
    type: 'date',
    //showgrid: false,
    //zeroline: false,
    //range: [0, 63]
  },
  yaxis: {
    //showgrid: false,
    //zeroline: false,
  },
};

export function LineChart(props) {
    const { data } = props;
    return (
      <Plot data={data} layout={ChartLayout} className="w-full mt-4" useResizeHandler={true}/>
    )
}
