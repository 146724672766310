import '../index.css';

export default function Terms(props) {
    const { close } = props;
    return (
        <div>
                <div className="flex justify-center fixed left-0 bottom-0 w-full h-full bg-zinc-900 bg-opacity-80 z-10 min-h-80">
                    <div className="card flex-shrink-0 w-full max-w-5xl shadow-2xl bg-base-100 p-8 overflow-y-auto">
                        <label onClick={close} className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                       
                        <h2 className="font-semibold text-2xl lg:text-sm mb-2">Пользовательское соглашение</h2>
                        <div className="text-2xl lg:text-sm">
                            Настоящий документ «Пользовательское соглашение» (далее - Соглашение) представляет собой предложение ООО «Открытые разработки», размещенное на сайте open-card.org (далее - «Сайт»), заключить договор на изложенных ниже условиях Соглашения.<br/>
                            1.Общие положения<br/>
                            1.1.Вы настоящим подтверждаете, что с момента регистрации на Сайте и в течение времени пользования Сайтом, а также персонализированными сервисами Сайта, вы являетесь Пользователем Сайта вплоть до вашего личного обращения в администрацию Сайта с требованием об отказе от любых взаимоотношений с Сайтом.<br/>
                            1.2.Использование вами Сайта любым способом и в любой форме в пределах его объявленных функциональных возможностей, включая:<br/>
                            а)просмотр размещенных на Сайте материалов;<br/>
                            б)регистрация и/или авторизация на Сайте;<br/>
                            в)размещение или отображение на Сайте любых материалов, включая но не ограничиваясь такими как: тексты, гипертекстовые ссылки, изображения, аудио и видео- файлы, сведения и/ или иная информация;<br/>
                            создает договор на условиях настоящего Соглашения в соответствии с положениями ст.437 и 438 Гражданского кодекса Российской Федерации.<br/>
                            1.3.Воспользовавшись любой из указанных выше возможностей по использованию Сайта вы подтверждаете, что:<br/>
                            а)ознакомились с условиями настоящего Соглашения в полном объеме до начала использования Сайта;<br/>
                            б)принимаете все условия настоящего Соглашения в полном объеме без каких-либо изъятий и ограничений с вашей стороны и обязуетесь их соблюдать или прекратить использование Сайта. Если вы не согласны с условиями настоящего Соглашения или не имеете права на заключение договора на их основе, вам следует незамедлительно прекратить любое использование Сайта;<br/>
                            в)Соглашение (в том числе любая из его частей) может быть изменено Сайтом без какого-либо специального уведомления. Новая редакция Соглашения вступает в силу с момента ее размещения на Сайте либо доведения до сведения Пользователя в иной удобной форме, если иное не предусмотрено новой редакцией Соглашения.<br/>
                            2. Общие условия пользования Сайтом<br/>
                            2.1.Сайт осуществляет продажу и предоставление услуг и товаров посредством веб-ресурса open-card.org, shop.open-dev.ru и сопутствующих сервисных служб Сайта.<br/>
                            2.2.Сайт предоставляет доступ к персонализированным сервисам.<br/>
                            2.3.Настоящим вы даете своё добровольное согласие на информирование вас обо всех действиях Сайта, связанных с продажей товара и/или оказанием услуг, в том числе о статусе выполнения заказа, а также об иных событиях любого характера, относящихся к сервисам Сайта.<br/>
                            2.4.Все вопросы по информационной поддержке Вы можете задать по адресу main@open-dev.ru.<br/>
                            2.5.Вы признаёте, что сопровождающее товар описание на Сайте не претендует на исчерпывающую информативность и может содержать неточности. Вы вправе направить все замечания по неточному описанию товара Сайту по адресу shop@open-dev.ru.<br/>
                            2.6.Вы признаёте, что Сайт прилагает достаточные усилия, чтобы внешний вид, упаковка и характеристики товаров соответствовали описаниям, приведенным в каталоге Сайта. Одновременно Сайт доводит до вашего сведения, что фактические внешний вид, упаковка и характеристики товара могут отличаться от этих описаний в случае ассортиментного товара, а также в случае внесения изменений в товар непосредственно производителем.<br/>
                            2.10.Вы проинформированы, что цена и наличие товара на Сайте изменяется на круглосуточно без предварительного уведомления об этом и указываются в индивидуальном статусе и подробной карточке товара, отображаемым в каталоге на Сайте.<br/>
                            3. Обязательства Пользователя при использовании Сайта<br/>
                            3.1.Вы соглашаетесь не использовать сервисы Сайта с целью:<br/> 
                            3.1.1.загрузки контента, который является незаконным, нарушает любые права третьих лиц; пропагандирует насилие, жестокость, ненависть и/или дискриминацию по расовому, национальному, половому, религиозному, социальному признакам; содержит недостоверные сведения и/или оскорбления в адрес конкретных лиц, организаций, органов власти;<br/>
                            3.1.2.побуждения к совершению противоправных действий, а также содействия лицам, действия которых направлены на нарушение ограничений и запретов, действующих на территории РФ;<br/>
                            3.1.3.нарушения прав несовершеннолетних лиц и/или причинение им вреда в любой форме;<br/>
                            3.1.4.ущемления прав меньшинств; <br/>
                            3.1.5.выдачи себя за другого человека или представителя организации и/или сообщества без достаточных на то прав, в том числе за сотрудников Сайта;<br/>
                            3.1.6.введения в заблуждение относительно свойств и характеристик каких-либо товаров из каталога на Сайте; некорректного сравнения товаров, а также формирования негативного отношения к лицам, (не) пользующимся определенными товарами, или осуждения таких лиц; <br/>
                            3.1.7.загрузки контента, который вы не имеете права делать доступным по законодательству РФ или согласно каким-либо контрактным отношениям; <br/>
                            3.1.8.загрузки контента, который затрагивает и/или содержит какой-либо патент, торговый знак, коммерческую тайну, фирменное наименование, авторские и смежные с ними права, а равно прочие права на результаты интеллектуальной деятельности, принадлежащие или правомерно используемые третьими лицами; <br/>
                            3.1.9.загрузки не разрешенной специальным образом рекламной информации и/или спама;  <br/>
                            3.1.10.сбора и обработки персональных данных, информации о частной жизни любых лиц;  <br/>
                            3.1.11.нарушения нормальной работы Сайта;<br/>
                            3.1.12.нарушения российских или международных норм права.<br/>
                            3.2.Вы соглашаетесь не использовать на Сайте бранных слов, непристойных и оскорбительных образов, сравнений и выражений, в том числе в отношении пола, расы, национальности, профессии, социальной категории, возраста, языка человека и гражданина, а также в отношении организаций, органов власти, официальных государственных символов (флагов, гербов, гимнов), религиозных символов, объектов культурного наследия (памятников истории и культуры).<br/>
                            3.3.Вы признаете и соглашаетесь, что Сайт имеет право (но не обязанность) по своему усмотрению отказать в размещении и/или удалить любой контент, доступный через сервисы Сайта.<br/>
                            
                            4.Политика конфиденциальности<br/>
                            4.1.Условия Политики конфиденциальности и отношения между вами и Сайтом, связанные с обработкой персональных данных, регулируются Федеральным Законом РФ No152-ФЗ от 27 июля 2006г. «О персональных данных». <br/>
                            4.2.Политика конфиденциальности действует в отношении персональных данных, которые Сайт получил или может получить от вас при регистрации и/или оформлении заказа на Сайте, и необходимые для выполнения обязательств со стороны Сайта в отношении приобретаемого вами товара/услуги и/или вашего доступа к сервисам Сайта.<br/>
                            5.Условия приобретения и доставки товара<br/>
                            5.1.Вы можете приобрести товар на Сайте, оплатив его доступными способами.<br/>
                            5.2.Настоящим вы соглашаетесь, что выбранный вами способ оплаты не подлежит изменению с момента оформления заказа на Сайте. <br/>
                            5.3.Настоящим вы соглашаетесь, что подтверждение заказа, оплаченного электронными деньгами, кредитной картой, с личного счета мобильного телефона, банковским переводом, происходит только после подтверждения списания денежных средств в счёт оплаты заказа.  <br/>
                            5.4.Вы подтверждаете, что оплата заказа электронными деньгами, кредитной картой, с личного счета мобильного телефона должна быть произведена в течение 5 календарных дней с момента его оформления на Сайте. Вы соглашаетесь, что в случае неоплаты заказа по истечении указанного срока, заказ может быть аннулирован.<br/>
                            5.5.Вы соглашаетесь, что в случае невозможности передачи вам товара по вашей вине, в том числе нарушения вами срока, в течение которого вы обязаны забрать товар, будет расцениваться Сайтом как ваш отказ от товара. При этом товар возвращается Сайту, а заказ считается аннулированным. <br/>
                            5.6.В случае вашего отказа от товара, а также в случае отсутствия заказанного вами товара, перечисленная Сайту предоплата за товар, за исключением расходов Сайта на доставку товара, будет возвращена вам не позднее чем через 10 календарных дней с даты предъявления.  <br/>
                            5.8.Все вопросы, связанные со сроками и условиями доставки товара, вы можете направить в Сайту по адресу - main@open-dev.ru<br/>
                            6. Ответственность<br/>
                            6.1 Сервисы Сайта могут содержать ссылки на другие ресурсы. Вы признаете и соглашаетесь с тем, что Сайт не несет никакой ответственности за доступность этих ресурсов и за их контент, а также за любые последствия, связанные с использованием вами контента этих ресурсов. <br/>
                            6.2.Вы также соглашаетесь с тем, что Сайт не несёт никакой ответственности за ваши персональные данные, которые вы предоставляете сторонним ресурсам и/или иным третьим лицам в случае перехода на них с Сайта.<br/>
                            6.3.Вы подтверждаете, что Сайт не отвечает за возможную потерю и/или порчу данных, которая может произойти из-за нарушения вами положений настоящего Соглашения, а также неправильного доступа и/или использования персонализированных сервисов Сайта. <br/>
                            6.4.Ответственность за действия несовершеннолетних, включая приобретение ими товаров из каталога на Сайте, лежит на законных представителях несовершеннолетних.<br/>
                            6.5.Вы соглашаетесь, что в случае неисполнения и/или ненадлежащего исполнения Сайтом обязательств по продаже и/или доставке вам товара в связи с предоставлением вами недостоверных и/или недействительных данных о себе, а равно невыполнение вами условий настоящего Соглашения, Сайт ответственности не несет.<br/>
                            
                            7.Реквизиты Сайта<br/>
                            ООО «Открытые разработки»<br/>
                            ИНН: 7729455841<br/>
                            Адрес: 142116, г.Подольск, ул. Лобачѐва 13, оф. 201. Электронная почта для обращений - main@open-dev.ru <br/>
                            Дата публикации – 12.11.2022<br/>
                        </div>

                            <p className='mt-4'><a name="policy"></a></p>
                            <h2 className="font-semibold text-2xl lg:text-sm mb-2">Политика конфиденциальности</h2>
                            <div className="text-2xl lg:text-sm">
                            Настоящий документ «Политика конфиденциальности» (далее – по тексту – «Политика») представляет собой правила использования сайтом – https://connect.unitx.pro (далее – Оператор) персональной информации Пользователя, 
                            которую Оператор, включая всех лиц, входящих в одну группу с Оператором, могут получить о Пользователе во время использования им любого из сайтов, сервисов, служб, программ, продуктов или услуг Оператора (далее – Сайт) 
                            и в ходе исполнения Оператором любых соглашений и договоров с Пользователем. Согласие Пользователя с Политикой, выраженное им в рамках отношений с одним из перечисленных лиц, распространяется на все остальные перечисленные лица. <br/>
                            Использование Сайта означает безоговорочное согласие Пользователя с настоящей Политикой и указанными в ней условиями обработки его персональной информации; в случае несогласия с этими условиями Пользователь должен воздержаться от использования Сайта.<br/>
                            1. Общие положения политики<br/>
                            1.1. Настоящая Политика является неотъемлемой частью Публичной оферты (далее – «Оферта»), размещенной и/или доступной в сети Интернет по адресу: https://connect.unitx.pro, а также иных заключаемых с Пользователем договоров, когда это прямо предусмотрено их условиями. <br/>
                            1.2. Настоящая Политика составлена в соответствии с Федеральным законом «О персональных данных» № 152-ФЗ от 27 июля 2006 г., а также иными нормативно-правовыми актами Российской Федерации в области защиты и обработки персональных данных и действует в отношении всех персональных данных, которые Оператор может получить от Пользователя, являющегося стороной по гражданско-правовому договору. <br/>
                            1.3. Оператор имеет право вносить изменения в настоящую Политику. При внесении изменений в заголовке Политики указывается дата последнего обновления редакции. Новая редакция Политики вступает в силу с момента ее размещения на сайте, если иное не предусмотрено новой редакцией Политики. <br/>
                            1.4. К настоящей Политике, включая толкование ее положений и порядок принятия, исполнения, изменения и прекращения, подлежит применению законодательство Российской Федерации. <br/>
                            2. Персональная информация Пользователей, которую обрабатывает Сайт <br/>
                            2.1. Под персональной информацией в настоящей Политике понимается: <br/>
                            2.1.1. информация, предоставляемая Пользователем самостоятельно при регистрации (создании учётной записи) или в процессе использования Сайта, включая персональные данные Пользователя. Обязательная для предоставления Сайтом информация помечена специальным образом. Иная информация предоставляется Пользователем на его усмотрение; <br/>
                            2.1.2. данные, которые передаются в автоматическом режиме Сайту в процессе их использования с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, данные файлов cookie, информация о браузере Пользователя (или иной программе, с помощью которой осуществляется доступ к Сайту), технические характеристики оборудования и программного обеспечения, используемых Пользователем, дата и время доступа к Сайту, адреса запрашиваемых страниц и иная подобная информация; <br/>
                            2.1.3. иная информация о Пользователе, обработка которой предусмотрена условиями использования Сайта. <br/>
                            2.2. Настоящая Политика применима только к информации, обрабатываемой в ходе использования Сайта. Сайт не контролирует и не несет ответственность за обработку информации сайтами третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на Сайте. <br/>
                            2.3. Сайт не проверяет достоверность персональной информации, предоставляемой Пользователем, и не имеет возможности оценивать его дееспособность. <br/>
                            3. Цели обработки персональной информации Пользователей <br/>
                            3.1. Сайт собирает и хранит только ту персональную информацию, которая необходима для предоставления сервисов или исполнения соглашений и договоров с Пользователем, за исключением случаев, когда законодательством предусмотрено обязательное хранение персональной информации в течение определенного законом срока. <br/>
                            3.2. Персональную информацию Пользователя Сайт обрабатывает в следующих целях: <br/>
                            3.2.1. идентификация стороны в рамках сервисов, соглашений и договоров с Сайтом; <br/>
                            3.2.2. предоставление Пользователю персонализированных сервисов и услуг, а также исполнение соглашений и договоров; <br/>
                            3.2.3. направление уведомлений, запросов и информации, касающихся использования Сайта, исполнения соглашений и договоров, а также обработка запросов и заявок от Пользователя; <br/>
                            3.2.4. улучшение качества работы Сайта, удобства его использования для Пользователя, разработка новых услуг и сервисов; <br/>
                            3.2.5. таргетирование рекламных материалов; <br/>
                            3.2.6. проведение статистических и иных исследований на основе обезличенных данных. <br/>
                            4. Условия обработки персональной информации Пользователей и её передачи третьим лицам <br/>
                            4.1. В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме случаев добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу лиц. <br/>
                            4.2. Сайт вправе передать персональную информацию Пользователя третьим лицам в следующих случаях: <br/>
                            4.3.1. Пользователь выразил согласие на такие действия; <br/>
                            4.3.2. Передача необходима для использования Пользователем определенного сервиса либо для исполнения определенного соглашения или договора с Пользователем; <br/>
                            4.3.3. Передача необходима для функционирования и работоспособности самого Сайта; <br/>
                            4.3.4. Передача предусмотрена российским или иным применимым законодательством в рамках установленной законодательством процедуры; <br/>
                            4.3.5. Такая передача происходит в рамках продажи или иной передачи бизнеса (полностью или в части), при этом к приобретателю переходят все обязательства по соблюдению условий настоящей Политики применительно к полученной им персональной информации; <br/>
                            4.3.6. В целях обеспечения возможности защиты прав и законных интересов Сайта или третьих лиц в случаях, когда Пользователь нарушает Пользовательское соглашение Сайта, настоящую Политику, либо документы, содержащие условия использования конкретных сервисов. <br/>
                            4.3.7. В результате обработки персональной информации Пользователя путем ее обезличивания получены обезличенные статистические данные, которые передаются третьему лицу для проведения исследований, выполнения работ или оказания услуг по поручению Сайта. <br/>
                            5. Изменение и удаление персональной информации. Обязательное хранение данных. <br/>
                            5.1. Пользователь может в любой момент изменить (обновить, дополнить) предоставленную им персональную информацию или её часть. <br/>
                            5.2. Права, предусмотренные п. 5.1. настоящей Политики могут быть ограничены в соответствии с требованиями законодательства. Например, такие ограничения могут предусматривать обязанность Сайта сохранить измененную или удаленную Пользователем информацию на срок, установленный законодательством, и передать такую информацию в соответствии с законодательно установленной процедурой государственному органу. <br/>
                            6. Обработка персональной информации при помощи файлов Cookie и счетчиков <br/>
                            6.1. Файлы cookie, передаваемые Сайтом оборудованию Пользователя и оборудованием Пользователя Сайту, могут использоваться Сайтом для предоставления Пользователю персонализированных сервисов, для таргетирования рекламы, которая показывается Пользователю, в статистических и исследовательских целях, а также для улучшения Сайта. <br/>
                            6.2. Пользователь осознает, что оборудование и программное обеспечение, используемые им для посещения сайтов в сети интернет могут обладать функцией запрещения операций с файлами cookie (для любых сайтов или для определенных сайтов), а также удаления ранее полученных файлов cookie. <br/>
                            6.3. Яндекс вправе установить, что предоставление определенного сервиса или услуги возможно только при условии, что прием и получение файлов cookie разрешены Пользователем. <br/>
                            6.4. Структура файла cookie, его содержание и технические параметры определяются Сайтом и могут изменяться без предварительного уведомления Пользователя. <br/>
                            6.5. Счетчики, размещенные Сайтом, могут использоваться для анализа файлов cookie Пользователя, для сбора и обработки статистической информации об использовании Сайта, а также для обеспечения работоспособности Сайта в целом или их отдельных функций в частности. Технические параметры работы счетчиков определяются Сайтом и могут изменяться без предварительного уведомления Пользователя.  <br/>
                            7. Защита персональной информации Пользователя <br/>
                            7.1. Сайт предпринимает необходимые и достаточные организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц. <br/>
                            8. Изменение Политики конфиденциальности <br/>
                            8.1. Сайт имеет право вносить изменения в настоящую Политику конфиденциальности. При внесении изменений в актуальной редакции указывается дата последнего обновления. Новая редакция Политики вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Политики. Действующая редакция постоянно доступна на странице по адресу https://open-card.org/terms_conditions#policy. <br/>
                            9. Контакты и вопросы по персональным данным <br/>
                            9.1. Все предложения, вопросы, запросы и иные обращения по поводу настоящей Политики и использования своих персональных данных Пользователь вправе направлять по адресу main@open-dev.ru.<br/>
                            Дата публикации – 12.11.2022<br/>
                        </div>

                    </div>
                </div>
            
        </div>
    )
}