import './index.css';
import React, { useEffect, useState } from 'react';
import Dashboard from './layouts/Dashboard';
import Main from './layouts/Main';
import { Navbar } from './Navbar';
import I18n from './I18n';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import {enGB, ru} from 'date-fns/esm/locale';
//import { convertDateToUTC } from './utils';
import axios from 'axios';
const dayjs = require('dayjs');
require('dayjs/locale/ru');
require('dayjs/locale/en');
registerLocale('en', enGB);
registerLocale('ru', ru);

//axios.defaults.baseURL = 'https://connect.unitx.pro';


export default function App() {
  const url = window.location.href;
  //const extUid = url.substring(url.lastIndexOf('/') + 1);
  const param = new URL(url).pathname.substring(1);
  var extUid = null;

  //console.log(param);
  if ((param === 'paysuccess') || (param === 'payfail')) {
    extUid = new URL(url).searchParams.get('Shp_id');
  }else{
    extUid = param;
  }
  //console.log(extUid);

  const [state, setState] = useState({
    uid: extUid?extUid:window.name,
    logged: false,
    balance: 0
  })
  
  const login = (u) => {
    //console.log("login");
    //const u = ref.current.value;
    axios.get('/user/', { headers: {'id': u} })
    .then((res) => {
        const data = res.data;
        axios.defaults.headers.common['id'] = u;
        window.name = u;
        setState({uid:u, logged: true, balance: data.balance});
    }).catch((error) => {
        console.log(error)
        if (error.response.status === 402) {
            axios.defaults.headers.common['id'] = u;
            window.name = u;
            setState({uid:u, logged: true, balance: 0});
        } else {
            axios.defaults.headers.common['id'] = undefined;
            setState({uid:null, logged: false, balance: 0});
            window.name = "";
        }
    });    
    };


  useEffect(()=>{
    const loc = I18n.getlocale();
    I18n.setlang(loc);
    dayjs.locale(loc);
    setDefaultLocale(loc==='ru' ? 'ru' : 'en');
    if (state.uid) {
      login(state.uid)
    }
  }, []);

  return (
    <div className="bg-repeat bg-base-200 p-6 w-full h-screen flex flex-col" style={{backgroundImage: "radial-gradient(hsla(var(--bc) /.2) .5px, hsla(var(--b2) /1) .5px)", backgroundSize: "5px 5px"}}>
      <Navbar login={login} state={state} setState={setState}/>
      {state.logged ? <Dashboard payed={state.balance>0}/>: <Main/>}
    </div>
  );
}