import './index.css';
import { useState, useEffect, useRef, forwardRef } from "react";
import { BiMenu, BiLogInCircle, BiLogOutCircle } from "react-icons/bi";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import PayDialog from './PayDialog';
import DatePicker from "react-datepicker";
import axios from 'axios';
import I18n from './I18n';
import HowToUse from './docs/HowToUse';
import Terms from './docs/Terms';
import Pricing from './docs/Pricing';
import Contacts from './docs/Contacts';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button onClick={onClick} ref={ref} className="btn btn-outline btn-base lg:btn-sm w-48 lg:w-32 lg:text-sm text-lg">{value}</button>
));

export function Navbar(props) {

    const ref = useRef();
    const { curDate, setCurDate, login, state, setState } = props;
    //const [u, setU] = useState(uid);
    const [doc, setDoc] = useState(null);
    const closeDialog = () => { setDoc(null); }
    //const inp = <input type="text" placeholder="UID" ref={ref} value={u} onChange={(e)=>{setU(e.target.value)}} className="input input-bordered input-md lg:input-sm text-xl lg:text-base placeholder-gray-500 max-w-md dark:focus:border-blue-500 w-full"/>
    const inp = <input type="text" placeholder="UID" ref={ref} defaultValue={state.uid} className="input input-bordered input-md lg:input-sm text-xl lg:text-base placeholder-gray-500 max-w-md dark:focus:border-blue-500 w-full"/>

    //console.log("navbar uid", state.uid)
    return (
        <div className="navbar bg-base-100 rounded-xl shadow-xl">
            <div className="navbar-start flex items-center">
                <div className="dropdown z-10">
                    <label tabIndex="0" className="btn btn-ghost rounded-btn lg:text-2xl text-4xl"><BiMenu/></label>
                    <ul tabIndex="0" className="menu dropdown-content p-2 shadow-2xl bg-base-100 rounded-box w-64 mt-4 shadow-3xl">
                        <li><a onClick={()=>{setDoc(<HowToUse close={closeDialog}/>)}}>{I18n.get('How to use')}</a></li>
                        <li><a onClick={()=>{setDoc(<Terms close={closeDialog}/>)}}>{I18n.get('Terms and Conditions')}</a></li>
                        <li><a onClick={()=>{setDoc(<Pricing close={closeDialog}/>)}}>{I18n.get('Pricing')}</a></li>
                        <li><a onClick={()=>{setDoc(<Contacts close={closeDialog}/>)}}>{I18n.get('Contacts')}</a></li>
                    </ul>
                </div>

                <div className="flex-1">
                    <div className="normal-case text-xl ml-2 hidden lg:inline">Connect.UnitX</div>
                    <div className="normal-case text-xl ml-2 inline lg:hidden">{!state.logged && inp}</div>
                </div>

            </div>
 
            <div className="navbar-end">
                {!state.logged ?
                <div className="flex items-center justify-end">
                    <div className="hidden lg:inline">{inp}</div>
                    <button onClick={()=>{login(ref.current.value)}} className="btn btn-square btn-ghost lg:text-xl text-3xl ml-2 text-accent">
                            <BiLogInCircle/>
                    </button>
                </div>:
                <div className='flex flex-row items-center justify-end'>
                    <PayDialog id={state.uid} balance={state.balance} />
        
                    <button onClick={()=>{
                        axios.defaults.headers.common['id'] = undefined;
                        setState({uid:null, logged: false, balance: 0});
                        window.name = '';
                    }} className="btn btn-square btn-ghost lg:text-xl text-3xl text-accent">
                        <BiLogOutCircle/>
                    </button>
                </div>
                }
        </div>
        {doc}
        </div>
    )
}