const I18n = {
    lang: 'en',
    dict: {'en': {}},
    getlocale() { const locale =  navigator.language || navigator.browserLanguage || ( navigator.languages || [ "en" ]) [0]; 
                  return locale.startsWith('ru')? 'ru':'en'
                },
    getlang() { return this.lang },
    setlang(lang) { if (this.dict.hasOwnProperty(lang)) { this.lang = lang; } return this.lang },
    setdict(dict) { this.dict = dict },
    updatedict(dict) { this.dict = Object.assign(this.dict, dict) },
    make_dict_en(dict) { for (let k in dict) { this.dict.en[k] = k } },
    get(text)   { return this.dict[this.lang].hasOwnProperty(text)? this.dict[this.lang][text]: text },
    retranslate() {
            const strings = document.querySelectorAll(".translated");
            strings.forEach(function(item) {
                item.innerText = I18n.get(item.innerText)
            });
    }
 };

const dict = {
'ru': {
    'Activity': 'Активность',
    'History': 'История',
    'No data': 'Нет данных',
    'From:': 'От:',
    'To:': 'До:',
    'Balance': 'Баланс',
    'Pay': 'Пополнить',
    'Amount RUB': 'Количество руб.',
    'Refill the balance': 'Пополнить баланс',
    'How to use': 'Как использовать',
    'Terms and Conditions': 'Соглашение и политика конфиденциальности',
    'Contacts': 'Контакты',
    'Pricing': 'Цены',
    'Site': 'Сайт',
    'Mail': 'Почта',
    'Support': 'Поддержка',
    'Minimalistic cloud service for sensor monitoring': 'Минималистичный сервис хранения телеметрических данных',
    }
};

I18n.updatedict(dict);
I18n.make_dict_en(dict.ru);
I18n.setlang(I18n.getlocale())

export default I18n;