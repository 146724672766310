import './index.css';
import { useState } from "react";
//import { LineChart, BarChart } from './Charts';
import I18n from './I18n';
import History from './History';
import { bit_test } from './utils';

const dayjs = require('dayjs');
var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.guess()

function getValExt(typ, val) {
    switch (typ) {
        case 1:
            return '°C';
        case 2:
        case 3:
            if (val === 1) {
                return '°C';
            } else {
                return '%';
            }
        case 4:
            return 'ppm';
        case 5:
            if (val === 1) {
                return '°C';
            } else {
                return '';
            }
        default:
            if (bit_test(val, 5)) {return '°C'}
            if (bit_test(val, 6)) {return '%'}
            if (bit_test(val, 7)) {return 'ppm'}
            if (bit_test(val, 8)) {return ''}
            break;
    }
    return '';
}

/*
5 - t
6 - h
7 - co2
8 - discr
*/



export function Card(props) {
    const { type, alias, device, time, value1, value2 } = props;
    const [ showHist, setShowHist] = useState(false);
    return (
        <>
        {showHist && <History type={type} alias={alias} device={device} close={()=>{setShowHist(false)}} />}
        <button onClick={()=>setShowHist(true)} className="card p-2 lg:m-4 mt-4 w-full lg:w-60 h-96 lg:h-56 bg-base-100 rounded-xl shadow-xl divide-y divide-base-200 btn text-black dark:text-current">   
            <div className='p-2 text-3xl lg:text-base text-2xl normal-case w-full'> {/*flex flex-row w-full*/}
                {alias}
            </div> 

            {((value1===null) || (value2===null)) && 
                <div className='p-2 flex w-full justify-center items-center grow '>
                    {(value1!==null) ?
                    <p className='text-9xl lg:text-6xl font-semibold'>{value1.toFixed(1)}{getValExt(type, 1)}</p>
                    :<div className='text-9xl lg:text-6xl font-semibold'>{value2}{getValExt(type, 2)}</div>
                    }
                </div>   
            }

            {(value1!==null) && (value2!==null) &&
            <div className='p-2 flex flex-col w-full justify-around items-center grow'>
                <div className='text-8xl lg:text-5xl font-semibold'>{value1.toFixed(1)}{getValExt(type, 1)}</div>
                <div className='text-8xl lg:text-5xl font-semibold'>{value2}{getValExt(type, 2)}</div>
            </div>
            }

            <div className='p-2 text-3xl lg:text-base text-2xl normal-case w-full'>
                {dayjs(time*1000).fromNow()}
            </div> 
        </button>
        </>
    )
}