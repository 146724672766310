import I18n from "../I18n"

export default function Main() {
    return (
        <div className="hero grow">
            <div className="hero-content text-center">
                <div className="max-w-xl">
                    <h1 className="text-7xl lg:text-5xl font-bold">Connect.UnitX</h1>
                    <p className="py-6 text-3xl lg:text-xl">{I18n.get('Minimalistic cloud service for sensor monitoring')}</p>
                </div>
            </div>
        </div>
    )
}