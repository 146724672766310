import './index.css';
import { useState, useEffect, forwardRef } from "react";
import { AiOutlineBarChart, AiOutlineLineChart, AiFillSave, AiOutlineSave } from "react-icons/ai";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { BiLoaderCircle } from "react-icons/bi";
import { LineChart } from './Charts';
import I18n from './I18n';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const dayjs = require('dayjs');

let csv_data = null;

const Loading = () =>{
    return (
        <div className='flex w-full my-32 justify-center'>
            <BiLoaderCircle className="animate-spin h-32 w-32 text-neutral-content"/>
        </div>
  )}

const NoDataText = () => {
    return (
        <div className='flex w-full text-center my-32 justify-center'>
            <div className='text-4xl lg:text-3xl'>{I18n.get('No data')}</div>
        </div>
    )
}

const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button onClick={onClick} ref={ref} className="btn btn-outline lg:btn-sm w-full text-3xl lg:text-xl"> {value} </button>
));

function exportToCSV(chartData) {
    var csvContent = "data:text/csv;charset=utf-8,";
  
  // Extract headers from the first object's keys
  var headers = Object.keys(chartData[0]);

  // Combine headers with "x" and "y" labels
  var allHeaders = ['x', 'y'].concat(headers);

  csvContent += allHeaders.join(",") + "\n";

  // Add data rows
  chartData.forEach(function(data) {
    var xValues = data.x;
    var yValues = data.y;

    // Make sure the "x" and "y" arrays have the same length
    var minLength = Math.min(xValues.length, yValues.length);

    for (var i = 0; i < minLength; i++) {
      var row = [xValues[i], yValues[i]];

      // Add additional values based on the headers
      headers.forEach(function(header) {
        row.push(data[header]);
      });

      csvContent += row.join(",") + "\n";
    }
  });
  
    // Create a temporary link element
    var link = document.createElement('a');
    link.href = encodeURI(csvContent);
    link.target = '_blank';
    link.download = 'data.csv';
  
    // Trigger the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
  


const ChartRender = (type, data) => {
    var dat1 = [];
    var dat2 = [];
    var ts = [];

    if (data.length === 0) {
        return <NoDataText/>
    }

    switch (type) {
        case 1:
            data.forEach(x => {
                dat1.push(x.value1 === null? null: x.value1.toFixed(1));
                ts.push(x.time*1000);
            });
            csv_data = [{x: ts, y: dat1, name: 't'}];
            return <LineChart data={[{x: ts, y: dat1, type: 'scatter', name: 't'}]}/>  //  fill: 'tozeroy',
        case 2:
        case 3:
            data.forEach(x => {
                dat1.push(x.value1 === null? null: x.value1.toFixed(1));
                dat2.push(x.value2);
                ts.push(x.time*1000);
            })
            csv_data = [{x: ts, y: dat1, name: 't'}, {x: ts, y: dat2, name: 'h'}];
            return <LineChart data={[{x: ts, y: dat1, type: 'scatter', name: 't'}, {x: ts, y: dat2, type: 'scatter', name: 'h'}]}/>
        case 4:
            data.forEach(x => {
                dat1.push(x.value1 === null? null: x.value1.toFixed(1));
                ts.push(x.time*1000);
            });
            csv_data = [{x: ts, y: dat1, name: 'ppm'}];
            return <LineChart data={[{x: ts, y: dat1, type: 'scatter', name: 'ppm'}]}/>
        case 5:
            data.forEach(x => {
                if (x.value2 === 2) {
                    dat1.push(null);
                    dat2.push(null);
                    ts.push((x.time-10)*1000);
                }
                dat1.push(x.value1 === null? null: x.value1.toFixed(1));
                dat2.push(x.value2);
                ts.push(x.time*1000);
            });
            csv_data = [{x: ts, y: dat1, name: 't'}, {x: ts, y: dat2, name: 'e'}];
            return <LineChart data={[{x: ts, y: dat1, type: 'scatter', name: 't'}, {x: ts, y: dat2, type: 'bar', name: 'e'}]}/>
        default:
            csv_data = null;
            return <NoDataText/>
    }
}

function History(props) {
    const { type, alias, device, close } = props;

    const [graph, setGraph] = useState(<Loading/>);
    const [from, setFrom] = useState(dayjs().subtract(1, 'day').toDate());
    const [to, setTo] = useState(dayjs().toDate());

    useEffect(() => {
        //console.log(`/state/${device}/${type}/${dayjs(from).unix()}/${dayjs(to).unix()}`)
        axios.get(`/state/${device}/${type}/${dayjs(from).unix()}/${dayjs(to).unix()}`)
        .then((res) => {
            setGraph(ChartRender(type, res.data));
        }).catch(() => {
            setGraph(<NoDataText/>);
        });
    }, [from, to]);

    return (
            <div className="flex items-center justify-center fixed left-0 bottom-0 w-full h-full bg-zinc-900 bg-opacity-80 z-10 overflow-y-auto min-h-80">
                <div className="card flex-shrink-0 w-full max-w-5xl shadow-2xl bg-base-100">
                    <label onClick={close}  className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <div className="card-body text-center text-3xl lg:text-2xl">
                        <span>{I18n.get('History')}&nbsp;{alias}</span>
                        {graph}
                    </div>
                    <div className='flex flex-row p-10 items-center justify-center space-x-4'>
                        <button className='btn btn-outline lg:btn-sm mr-1' onClick={()=>{
                            const dt = to - from;
                            setFrom(from-dt);
                            setTo(to-dt);
                        }}><BsChevronLeft/></button>
                        <div className='text-3xl lg:text-2xl'>{I18n.get('From:')}</div>
                        <div><DatePicker selected={from} onChange={setFrom} dateFormat={I18n.getlocale()==='ru'? "dd/MM/yy": "MM/dd/yy"} popperPlacement="top-end" customInput={<CustomInput/>}/></div>
                        <div className='text-3xl lg:text-2xl'>{I18n.get('To:')}</div>
                        <div><DatePicker selected={to} onChange={setTo} dateFormat={I18n.getlocale()==='ru'? "dd/MM/yy": "MM/dd/yy"} popperPlacement="top-end" customInput={<CustomInput/>}/></div>
                        <div/>
                        <button className='btn btn-outline lg:btn-sm' onClick={()=>{
                            const dt = to - from;
                            setFrom(from+dt);
                            setTo(to+dt);
                            }}><BsChevronRight/>
                        </button>
                        {/*
                        <button className='ml-8 btn lg:btn-sm btn-outline' 
                        onClick={()=>{
                            if (csv_data !== null) {
                                exportToCSV(csv_data);
                            }
                        }}
                        ><AiOutlineSave/>&nbsp;CSV
                        </button>
                    */}
                    </div>
                </div>
            </div>
    )
}

export default History;