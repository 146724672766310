import '../index.css';
import I18n from '../I18n';

export default function Pricing(props) {
    const { close } = props;
    return (
        <div>
                <div className="flex items-center justify-center fixed left-0 bottom-0 w-full h-full bg-zinc-900 bg-opacity-80 z-10 overflow-y-auto min-h-80">
                    <div className="card flex-shrink-0 w-full max-w-5xl shadow-2xl bg-base-100 flex flex-col space-y-8 p-8 overflow-y-auto">
                        <label onClick={close} className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                        <h2 className="font-semibold text-3xl lg:text-lg">Контактные данные</h2>
                        <div className="text-3xl lg:text-lg">
                            ООО "Открытые разработки", г.Москва <br/><br/>
                            {I18n.get('Site')}: https://open-dev.ru<br/>
                            {I18n.get('Mail')}: main@open-dev.ru<br/>
                            {I18n.get('Support')}: https://support.unitx.pro<br/>
                        </div>
                    </div>
                </div>
            
        </div>
    )
}