import '../index.css';
import React, { useState, useEffect } from "react";
import { Card } from '../Card';
import axios from 'axios';
import useInterval from '../useInterval';
import { convertDateToUTC } from '../utils';

const dayjs = require('dayjs');
//var isToday = require('dayjs/plugin/isToday')
//var isYesterday = require('dayjs/plugin/isYesterday')
var utc = require('dayjs/plugin/utc')
//dayjs.extend(isToday)
//dayjs.extend(isYesterday)
dayjs.extend(utc)

const sortByTime = (a, b) => {
    return b.time - a.time;
  };

let devices = [];

export default function Dashboard(props) {
    const { payed } = props;
    const [cards, setCards] = useState(null)

    const getCards = () => {
        axios.get(`/user/devices`)
        .then((res) => {
            devices = res.data;
            devices.sort(sortByTime);
            const c = devices.map((x, i)=>{
               return <Card key={i} type={x.type} alias={x.alias || x.device} device={x.device} time={x.time} value1={x.value1} value2={x.value2} />
            })
            setCards(c);
        }).catch((error) => {
            console.log(error)
        });    
    }

    const getCardsLive = (par) => {
        axios.get(`/state/now`)
        .then((res) => {
            const c = Object.entries(res.data).map((kv, i)=>{
                const [k, v] = kv;
                const x = JSON.parse(v);
                //x.device = k;
                //return x
                //return <Card key={i} type={x.type} alias={x.alias || k} device={k} time={x.time} value1={x.value1} value2={x.value2} />

                const objToUpdate = devices.find(obj => obj.device === k);
                if (objToUpdate) {
                    objToUpdate.alert = x.alert;
                    objToUpdate.alert_time = x.alert_time;
                    objToUpdate.alias = x.alias;
                    objToUpdate.rssi = x.rssi;
                    objToUpdate.time = x.time;
                    objToUpdate.type = x.type;
                    objToUpdate.value1 = x.value1;
                    objToUpdate.value2 = x.value2;
                } else {
                    x.device = k;
                    devices.push(x);
                }
            });
            //const objToUpdate = array.find(obj => obj.name === 'Jane');
            const cv = devices.map((x, i)=>{
                return <Card key={i} type={x.type} alias={x.alias || x.device} device={x.device} time={x.time} value1={x.value1} value2={x.value2} />
             })
            setCards(cv);
        }).catch((error) => {
            console.log(error)
        });    
    }

    useEffect(() => {
        if (payed) {
            getCards();
        } else {
            getCardsLive("today");
        }
    }, []);
    
    useInterval(() => {
        getCardsLive("today");
    }, 10000);

    return (
        <div className="flex flex-wrap justify-start">
            {cards}
        </div>
    )
}