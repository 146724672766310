import './index.css';
import { useState } from "react";
import { BiWallet } from "react-icons/bi";
import I18n from './I18n';
import axios from 'axios';


function PayDialog(props) {

    const { id, balance } = props;
    const [show, setShow] = useState(false);
    const [rub, setRub] = useState(300);

    const pay=()=>{
        if (id === undefined) { return; }

        console.log({
            id: id,
            sum: rub
        })
        axios.post('/user/pay/', {
            id: id,
            sum: parseInt(rub, 10)
        })
        .then((res) => { window.location.replace(res.data) })
    }

    return (
        <div>
            <div onClick={()=>setShow(true)} className='flex flex-row space-x-3 items-center mr-8 cursor-pointer'>
                <BiWallet className='lg:text-xl text-3xl'/> 
                <p className='lg:text-xl text-3xl'>{balance}</p>
            </div>
            {show &&
                <div className="flex items-center justify-center fixed left-0 bottom-0 w-full h-full bg-zinc-900 bg-opacity-80 z-10 overflow-y-auto min-h-80">
                    <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100 flex flex-col space-y-8 p-8">
                        <label onClick={()=>{setShow(false)}}  className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                        <span>{I18n.get('Refill the balance')}</span>
                        <input value={rub} onChange={(e)=>{setRub(e.target.value)}}  type='text' className='input input-bordered w-full mt-8' placeholder={I18n.get('Amount RUB')}/>
                        <button className='btn btn-primary' onClick={pay}>
                            {I18n.get('Pay')} 
                        </button>

                    </div>
                </div>
            }
        </div>
    )
}

export default PayDialog;