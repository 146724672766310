import '../index.css';

export default function HowToUse(props) {
    const { close } = props;
    return (
        <div>
                <div className="flex items-center justify-center fixed left-0 bottom-0 w-full h-full bg-zinc-900 bg-opacity-80 z-10 overflow-y-auto min-h-80">
                    <div className="card flex-shrink-0 w-full max-w-5xl shadow-2xl bg-base-100 flex flex-col space-y-8 p-8 overflow-y-auto">
                        <label onClick={close} className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                        <h2 className="font-semibold text-3xl lg:text-lg">Как использовать</h2>
                        <div className="text-3xl lg:text-lg" >
                            <p className='font-bold'>Что это такое?</p>
                            Connect.UnitX - это минималистичный сервис для хранения, отображения и экспорта данных от различных сенсорных устройств.<br/>
                            <br/>
                            <p className='font-bold'>Чем он отличается?</p>
                            Оень простая работа (без смс и регистрации) пользователей и самих устройств.<br/>
                            Автоматическ создаваемый токен является и логином, и паролем, и ключом для ваших устройств.<br/>
                           <br/>
                            
                           <p className='font-bold'>Возможности:</p>
                           <ul className="list-disc list-inside">
                           <li>Сохранение показаний от сенсоров.</li>
                           <li>Отправка уведомления в Telegram или приложение при превышении пороговых значений.</li>
                           <li>Хранение и вывод истории за период.</li>
                           <li>Экспорт данных.</li>
                           </ul><br/>
                           
                            Технические особенности:<br/>
                        <ul className="list-disc list-inside">
                            <li>Сервис не хранит личных данных пользователей.</li>
                            <li>Сервер работает со временем в UTC (обращайте внимание на свой часовой пояс).</li>
                            <li>Первая ячейка для хранения данных формата float32.</li>
                            <li>Вторая ячейка для хранения данных формата int64.</li>
                            <li>Частота сохранения показаний: от 1 минуты.</li>
                            <li>Срок хранения истории: 365 дней.</li>
                            <li>Количество устройств: неограничено.</li>
                            <li>При длительной неактивности аккаунт может быть удалён.</li>
                        </ul><br/>
                        </div>
                    </div>
                </div>
            
        </div>
    )
}