import '../index.css';

export default function Pricing(props) {
    const { close } = props;
    return (
            <div className="flex items-center justify-center fixed left-0 bottom-0 w-full h-full bg-zinc-900 bg-opacity-80 z-10 overflow-y-auto min-h-80">
                <div className="card flex-shrink-0 w-full max-w-5xl shadow-2xl bg-base-100 flex flex-col space-y-8 p-8 overflow-y-auto">
                    <label onClick={close} className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <h2 className="font-semibold text-3xl text-center">Стоимость</h2>
                    <div className="text-3xl lg:text-lg">
                        Сервис предполагает варианты платного и бесплатного использования.<br/><br/>

                        Бесплатный тариф предполагает следующий функционал:
                        <ul className="list-disc list-inside">
                            <li>Хранение текущего состояния устройства</li>
                            <li>Хранение последнего состояния устройства за предыдущий день</li>
                        </ul>
                        <br/>
                        Платный тариф предполагает следующий функционал: <br/>
                        <ul className="list-disc list-inside">
                        <li>Хранение истории показаний сенсоров (365 дней)</li>
                        <li>Уведомления в телеграм о превышении пороговых значений.</li>
                        <li>Отображение алиасов (имён) устройств вместо их MAC адресов.</li>
                        <li>Отображение графиков показаний сенсоров.</li>
                        </ul><br/>
                        Стоимость в день определяется из формулы: [количество уникальных устройств в день] х 3 руб.<br/>
                        Пример: в аккаунте постоянно находится одно устройство, то в месяц на хранение его данных понадобится 3х31=93 руб.<br/>
                        Если в сутках (в UTC) устройства не было, то списания не происходит.<br/>
                        Списание проиходит в 00:00 UTC.<br/>
                        <br/>
                    </div>
                </div>
            </div>
    )
}